<!--  登录   -->
<template>
  <div class="container">
    <heads :msg="title_msg" :states="1"></heads>
    <div class="content">
      <div v-show="!isVerifyCode">
        <div class="item">
          <span class="phone">手机号：{{ mobile }}</span>
          <span v-show="iscode" class="code_btn" @click="getCode">{{ code_text }}</span>
          <span v-show="againcode" class="code_btn">{{ code_text }}</span>
        </div>
        <div class="item">
          <input v-model="code" type="text" class="code_inp" placeholder="请输入验证码">
        </div>
        <div v-show="code_err" class="item_msg">
          <span class="error_msg">验证码错误</span>
        </div>
        <div class="item" style="margin-top: 40px;">
          <van-button class="code_sure" :disabled="!code.length" @click="sureCode">确 认</van-button>
        </div>
      </div>
      <div v-show="isVerifyCode">
        <div class="item">
          <span class="phone">手机号：{{ mobile }}</span>
        </div>
        <div class="item">
          <div class="input_item">
            <input v-model="newpwd" :type="flagType" class="pwd_inp" placeholder="请输入新密码" @blur="checkPwd(newpwd,null,false)">
            <img v-if="flagType === 'text'" src="https://passport.zhulong.com/img/loginimg/kejian.png" alt="" class="show_eye" @click="showPSW(1)">
            <img v-else src="https://passport.zhulong.com/img/loginimg/bukejian.png" alt="" class="show_eye" @click="showPSW(1)">
          </div>
        </div>
        <div class="item_msg" style="padding-bottom:10px;">
          <span class="error_msg" v-html="newPassTip"></span>
        </div>

        <div class="item_safety">
          <div class="safety">
            <span class="span_safe">安全强度：</span>
            <span class="span_safe_common" :class="{span_safe_1:isLevelOne,span_safe_0:!isLevelOne}" />
            <span class="span_safe_common" :class="{span_safe_2:isLevelTwo,span_safe_0:!isLevelTwo}" />
            <span class="span_safe_common" :class="{span_safe_3:isLevelThree,span_safe_0:!isLevelThree}" />
          </div>
        </div>

        <div class="item">
          <div class="input_item">
            <input v-model="sureNewpwd" :type="sureFlagType" class="pwd_inp" placeholder="请再次输入新密码" @blur="checkPwd(newpwd,sureNewpwd,false)">
            <img v-if="sureFlagType === 'text'" src="https://passport.zhulong.com/img/loginimg/kejian.png" alt="" class="show_eye" @click="showPSW(2)">
            <img v-else src="https://passport.zhulong.com/img/loginimg/bukejian.png" alt="" class="show_eye" @click="showPSW(2)">
          </div>
        </div>
        <div v-show="showErrorCheckTip" class="item_msg1" style="padding-bottom:10px;">
          <span class="error_msg1">密码不一致，请重新输入</span>
        </div>
        <div class="item" style="margin-top: 40px;">
          <van-button class="code_sure" :disabled="!newpwd.length || !sureNewpwd.length" @click="savePwd">确 认</van-button>
        </div>
        <div class="item">
          <span class="tip">
            密码由6-16(字母、数字、符号)组成，区分大小写<br>(不能是6位纯数字，不能包含空格)<br>建议使用英文字母加数字或符号的混合密码
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import WechatState from '@/utils/isWechatState.js'
import { getLoginUserInfo, sendMobile, vertifyMobile, updatePwd } from '@/api/login.js'
import { Toast } from 'vant'
export default {
  components: { heads },
  data() {
    return {
      title_msg: '修改密码',
      uid: Cookies.get('uid') ? Cookies.get('uid') : '',
      mobile: '',
      code: '',
      newpwd: '',
      sureNewpwd: '',
      code_text: '获取验证码',
      iscode: true,
      againcode: false,
      isVerifyCode: false,
      flagType: 'password',
      sureFlagType: 'password',
      pwdPassRule: false,
      errorTip: false,
      newPassTip: '',
      showErrorCheckTip: false,
      timer: null,
      s: 60,
      code_err: false,
      // 密码等级
      isLevelOne: false,
      isLevelTwo: false,
      isLevelThree: false
    }
  },
  watch: {
    'newpwd'(newValue) {
      if (newValue.length < 6) {
        this.isLevelOne = false
        this.isLevelTwo = false
        this.isLevelThree = false
        return
      }
      let lv = -1
      if (newValue.match(/[a-z]/ig)) {
        lv++
      }
      if (newValue.match(/[0-9]/ig)) {
        lv++
      }
      if (newValue.match(/(.[^a-z0-9])/ig)) {
        lv++
      }
      if (newValue.length < 6 && lv > 0) {
        lv--
      }
      switch (lv) {
        case 0:
          this.isLevelOne = true
          this.isLevelTwo = false
          this.isLevelThree = false
          break
        case 1:
          this.isLevelOne = false
          this.isLevelTwo = true
          this.isLevelThree = false
          break
        case 2:
          this.isLevelOne = false
          this.isLevelTwo = false
          this.isLevelThree = true
          break
      }
    }
  },
  created() {
    if (!WechatState.isWechatMiniprogram) {
      // 创建script标签，引入外部文件
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = './TCaptcha.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  },
  mounted() {
    this.funcGetInfo()
  },
  methods: {
    funcGetInfo() {
      getLoginUserInfo().then(res => {
        if (res.errNo === 0) {
          this.mobile = res.result.mobile
          this.userName = res.result.username
        }
      })
    },
    getCode() {
      // 获取验证码
      this.iscode = false
      this.againcode = true
      this.gettime()
      sendMobile({ uid: this.uid }).then(res => {
        console.log('成功')
      })
    },
    sureCode() {
      // 验证码验证
      const info = {
        uid: this.uid,
        code: this.code
      }
      vertifyMobile(info).then(res => {
        if (res.errNo === 0) {
          this.isVerifyCode = true
          this.code_err = false
        } else {
          this.isVerifyCode = false
          this.code_err = true
        }
      })
    },
    gettime() {
      this.timer = setInterval(() => {
        // 某些定时器操作
        this.s--
        // this.code_text = '重发(' + this.s + ')'
        this.code_text = this.s + 's'
        if (this.s <= 0) {
          this.iscode = true
          this.againcode = false
          this.code_text = '重新获取'
          clearInterval(this.timer)
          this.s = 60
        }
      }, 1000)
    },
    showPSW(e) {
      console.log(e)
      switch (e) {
        case 1:
          this.flagType = this.flagType === 'text' ? 'password' : 'text'
          break
        case 2:
          this.sureFlagType = this.sureFlagType === 'text' ? 'password' : 'text'
          break
      }
    },
    savePwd() {
      this.checkPwd(this.newpwd, this.sureNewpwd, true)
      if (!this.showErrorCheckTip && !this.errorTip) {
        const info = {
          uid: this.uid,
          newpwd: this.newpwd
        }
        updatePwd(info).then(res => {
          if (res.errNo === 0) {
            Toast('修改密码成功')
            setTimeout(() => {
              window.location.href = window.location.origin + '/ucenter/mine'
            }, 500)
          } else {
            Toast(res.msg)
            if (res.errNo === 41) {
              this.$router.go(0)
            }
          }
        })
      }
    },
    checkPwd(newPass, checkPass, tag) {
      if ((checkPass != null && checkPass.toString().trim().length > 0) || tag === true) {
        this.showErrorCheckTip = checkPass !== newPass
      }
      if (checkPass == null || tag === true) {
        this.pwdPassRule = false
        if (newPass.length > 0) {
          if (newPass.length < 6) {
            this.errorTip = true
            this.newPassTip = '密码至少包含6个字符'
          } else if (newPass.length > 16) {
            this.errorTip = true
            this.newPassTip = '密码不能超过16位'
          } else if (!isNaN(newPass)) {
            this.errorTip = true
            this.newPassTip = '密码不能是纯数字'
          } else if (/^(000000|111111|222222|333333|444444|555555|666666|777777|888888|999999)/.test(newPass)) {
            this.errorTip = true
            this.newPassTip = '密码前六位不能是相同的数字'
          } else if (newPass === this.userName) {
            this.errorTip = true
            this.newPassTip = '密码不能和用户名相同'
          } else if (!(/^\S*$/.test(newPass))) {
            this.errorTip = true
            this.newPassTip = '密码不能包含空格'
          } else {
            this.pwdPassRule = true
            this.errorTip = false
            this.newPassTip = ''
          }
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.content{
  // width: 100%;
  padding: 80px 36px;
  font-family: PingFangSC-Regular;
  position: relative;
}
.item_msg{
  position: absolute;
  top: 320px;
  width: calc(100% - 72px);
  height: 40px;
  .error_msg{
    font-family: PingFangSC-Regular;
    margin-left: 10px;
    color: #ee2e2e;
    font-size: 28px;
  }
}
.item_msg1{
  position: absolute;
  top: 550px;
  width: calc(100% - 72px);
  height: 40px;
  font-family: PingFangSC-Regular;
  .error_msg1{
    margin-left: 10px;
    color: #ee2e2e;
    font-size: 28px;
  }
}
.item_safety{
  height: 42px;
  width: calc(100% - 72px);
  // margin-top: -20px;
  padding-top: 10px;
  margin-bottom: 50px;
  .safety{
    height: 42px;
    // width: 360px;
    margin: 0 0 30px 0;
    .span_safe {
      width: 122px;
      height: 44px;
      font-size: 28px;
      line-height: 44px;
      color: #999;
    }
  }
  .span_safe_common {
    display: inline-block;
    width: 72px;
    height: 12px;
    margin-bottom: 4px;
  }
  .span_safe_0 {
    background-color: #dfdfdf;
  }
  .span_safe_1 {
    background-color: #f14f4f;
  }
  .span_safe_2 {
    background-color: #00a0e9;
  }
  .span_safe_3 {
    background-color: lightgreen;
  }
}
.item{
  // width: calc(100% - 72px);
  width: 100%;
  height: 94px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input::placeholder{
    color: #999;
  }
  .phone{
    font-size: 32px;
    color: #000;
    font-weight: 400;
  }
  .code_btn{
    display: inline-block;
    width: 260px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    font-size: 28px;
    color: #ee2e2e;
    background-color: #fff;
    border: 1px solid #ee2e2e;
  }
  .code_inp{
    width: 100%;
    height: 92px;
    line-height: 92px;
    padding: 0 20px;
    font-size: 32px;
    border: 1px solid #e0e0e0;
  }
  .code_sure{
    width: 100%;
    font-size: 32px;
    color: #fff;
    background-color: #ee2e2e;
    border: none;
  }

  .input_item{
    width: 100%;
    height: 92px;
    padding: 0 20px 0 0;
    border: 1px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pwd_inp{
    width: calc(100% - 100px);
    height: 92px;
    line-height: 92px;
    padding: 0 20px;
    font-size: 32px;
  }
  .show_eye {
    display: block;
    width: 40px;
  }
  .tip{
    width: 100%;
    font-size: 28px;
    color: #999;
    text-align: center;
  }
}
</style>
